import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45check_45global from "/opt/build/repo/packages/member-webapp-admin/middleware/01.auth-check.global.ts";
import _02_45user_45session_45loaded_45global from "/opt/build/repo/packages/member-webapp-admin/middleware/02.user-session-loaded.global.ts";
import _03_452fa_45check_45global from "/opt/build/repo/packages/member-webapp-admin/middleware/03.2fa-check.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45check_45global,
  _02_45user_45session_45loaded_45global,
  _03_452fa_45check_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/build/repo/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}